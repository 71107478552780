
import { defineComponent } from 'vue';
import PageNotFoundContent from '@/components/PageNotFoundContent.vue';

export default defineComponent({
  components: {
    PageNotFoundContent,
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    setTimeout(() => {
      this.loadDocument();
    }, 0);
  },
  props: {
    documentSlug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    document: {} as { title: string; content: string; },
  }),
  created() {
    this.loadDocument();
  },
  methods: {
    async loadDocument() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `documents/${this.documentSlug}/`,
      });

      if (responseData.status === 200) {
        this.document = responseData.body;
        this.status = 'loaded';
      } else if (responseData.status === 404) {
        this.status = 'not_found';
      } else {
        this.status = 'error';
      }
    },
  },
});
